<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">鉴定机构管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">考务安排</a>
        </span>
      </div>

      <div class="framePage-body">
        <!-- 查询 -->
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <!-- <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:</span>
              <el-input
                size="small"
                placeholder="请输入考试名称"
                v-model="examName"
              ></el-input>
            </div> -->
            <!-- <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="getData()"
              >搜索</el-button
            > -->
            <el-button
              class="bgc-bv"
              style="margin: 0 10px"
              round
              @click="newExamAddEdit('add')"
              >新建考试</el-button
            >
          </div>
        </div>
        <!-- 列表 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <!-- <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              /> -->
              <el-table-column
                label="考试名称"
                align="left"
                prop="examName"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试时间"
                align="center"
                width="260"
                show-overflow-tooltip
              >
                <template slot-scope="scope"
                  >{{ scope.row.theoryTestStart | moment }} ~
                  {{ scope.row.theoryTestEnd | moment }}</template
                >
              </el-table-column>
              <el-table-column
                label="考试人数"
                align="center"
                prop="examNumber"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                label="考试状态"
                align="center"
                prop="examStatus"
                show-overflow-tooltip
              />
              <el-table-column
                label="发布状态"
                align="center"
                prop="isPublish"
                show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{scope.row.isPublish ? '已发布':'未发布'}}
              </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="300">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="newExamAddEdit('edit', scope.row.examinationId,scope.row.paperId,scope.row.isPublish)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.allocatingExamRoom"
                    @click="Supervisor(scope.row)"
                    >选择学员</el-button
                  >

                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.allocatingExamRoom"
                    @click="handleProductionPermit(scope.row)"
                    >生成准考证</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="scope.row.isPublish || !scope.row.allocatingExamRoom"
                    @click="handleIssue(scope.row)"
                    >发布</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLookStudent(scope.row)"
                    >学员档案</el-button
                  >
                  <!-- <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    :disabled="!scope.row.allocatingExamRoom"
                    @click="downloadAdmissionTicket(scope.row)"
                    >下载准考证</el-button
                  > -->
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>

    <!-- 选择计划 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      center
      @close="setCancel"
      class="exportdialog"
      ><div class="ovy-a" style="height: auto">
        <div class="searchbox" style="display: flex; margin-bottom: 15px">
          <div title="工种" class="searchboxItem ci-full">
            <span class="itemLabel" style="width: 3rem">工种:</span>
            <el-input
              size="small"
              placeholder="请输入工种"
              v-model="compOccupationName"
            ></el-input>
          </div>
          <el-button
            class="bgc-bv"
            size="small"
            style="margin: 0 10px"
            round
            @click="getData()"
            >搜索</el-button
          >
        </div>
        <el-table
          ref="multipleTable"
          :data="tablePlanData"
          size="small"
          tooltip-effect="dark"
          style="width: 100%"
          row-key="planId"
          @selection-change="checkListData"
          :header-cell-style="tableHeader"
          stripe
        >
          <el-table-column
            type="selection"
            width="50px"
            align="center"
            fixed
            :reserve-selection="true"
            :selectable="selectable"
          ></el-table-column>
          <!-- <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              /> -->
          <el-table-column
            label="工种"
            align="left"
            prop="compOccupationName"
            show-overflow-tooltip
          />
          <el-table-column
            label="报名起止时间"
            align="left"
            prop="compOccupationName"
            show-overflow-tooltip
          />
          <el-table-column
            label="等级"
            align="left"
            prop="occupationLevel"
            show-overflow-tooltip
          />
          <el-table-column
            label="资格审核通过人数"
            width="150"
            align="left"
            prop="studentNumber"
            show-overflow-tooltip
          />
          <el-table-column
            label="报名人数"
            width="150"
            align="left"
            prop="examPeopleJoin"
            show-overflow-tooltip
          />
          <!-- <Empty slot="empty" /> -->
        </el-table>
        <div class="Batch">
          <div class="BatchBox">
            <div class="BatchBoxFirst">
              <p>已选工种</p>
              <p>（{{ backArr.length }}）</p>
            </div>
            <div class="BatchBoxSecond ovy-a">
              <div class="BatchforBox">
                <div
                  v-for="(item, index) in backArr"
                  :key="index"
                  class="BatchforFlex"
                >
                  <div class="BatchImgBox" @click="deleteImg(item)">
                    <img
                      :src="require('@/assets/close_.png')"
                      alt=""
                      @mouseover="transToWhite(index, $event)"
                      @mouseleave="transToBlack(index, $event)"
                    />
                  </div>
                  <span style="padding-left: 5px"
                    >{{ item.compOccupationName }}({{
                      item.occupationLevel
                    }}级)</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="setCancel">取 消</el-button>
        <el-button @click="setSure" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 生产准考证 -->
    <el-dialog
      title="生成准考证"
      :visible.sync="dialogProductionPermit"
      center
      @close="setProductionPermitCancel"
    >
      <div class="ovy-a" style="height: auto">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="140px"
        >
          <el-form-item label="学员随机分配到：" prop="roomNumber">
            <el-input
              v-model="ruleForm.roomNumber"
              placeholder="请输入考试数量"
              maxlength="100"
              size="small"
            ><template slot="append">个考场</template></el-input>
          </el-form-item>
          <el-form-item label="每个考场分：" prop="customizeExamNumber">
            <el-input
              v-model="ruleForm.customizeExamNumber"
              placeholder="请输入考场人数"
              maxlength="100"
              size="small"
            >
              <template slot="append">人</template></el-input
            >
          </el-form-item>
          <el-form-item label="准考证号编号：" prop="examNo">
            <el-input
              v-model="ruleForm.examNo"
              placeholder="请输入准考证号编号"
              maxlength="100"
              size="small"
            ></el-input>
          </el-form-item>
          <p style="color: red">如果准考证号为空，则按默认编号设置</p>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer flexcc">
        <el-button @click="setProductionPermitCancel">取 消</el-button>
        <el-button @click="setProductionPermit" class="bgc-bv" type="primary"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import List from "@/mixins/List";
import PageNum from "@/components/PageNum.vue";
import Empty from "@/components/Empty.vue";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  components: {
    PageNum,
    Empty,
  },
  mixins: [List],
  data() {
    return {
      examName: "", //考试名称
      //监考老师||监督人员
      dialogTitle: "", //
      dialogVisible: false,
      tablePlanData: [],
      compOccupationName: "", //工种名称
      backArr: [],
      examinationId: "", //考务Id
      examNumber: "", //考试人数
      dialogProductionPermit: false, //生产准考证
      ruleForm: {
        roomNumber: "", //考场数量
        customizeExamNumber: "", //考场人数
        examNo: "", //准考证号编号
      },
      rules: {
        roomNumber: [
          {
            required: true,
            message: "请输入考场数量",
            trigger: "blur",
          },
        ],
        customizeExamNumber: [
          {
            required: true,
            message: "请输入考场人数",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {},
  methods: {
    // 新建考试
    newExamAddEdit(stu, examinationId = "",paperId='',isPublish) {
      this.$router.push({
        path: "/web/appraisalInstitution/examinationArrangementAdd",
        query: {
          stu,
          examinationId,
          paperId,
          isPublish,
          active:0
        },
      });
      sessionStorage.setItem("active", 0);
    },
    // 获取数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };

      // 鉴定考试工种id
      if (this.occupationId) {
        params.examOccupationId = this.occupationId;
      }
      this.doFetch({
        url: "/biz/examination/pageList",
        params,
        pageNum,
      });
      
    },
    //选择学员
    Supervisor(row) {
      this.dialogTitle = "选择学员";
      this.dialogVisible = true;
      this.examinationId = row.examinationId;
      this.getPlanList();
    },
    getPlanList() {
      let searchData = {};
      if (this.compOccupationName) {
        searchData.compOccupationName = this.compOccupationName;
      }
      this.$post("/biz/examination/choosePlan", {
        searchData,
        examinationId: this.examinationId,
      }).then((ret) => {
        this.tablePlanData = ret.data.list || [];
        this.backArr = ret.data.selectedList || [];
      });
    },
    // 勾选列表数据
    checkListData(rowArr) {
      rowArr.map((el) => {
        if (Number(el.examPeopleJoin) != Number(el.studentNumber)) {
          this.$message({
            message: "资格审核通过人数与报名人数不相符",
            type: "error",
          });
        }
      });
      this.backArr = rowArr;
      console.log(rowArr);
      this.multipleTable = rowArr;
    },
    //选择计划 -- 确认
    setSure() {
      let list = [];
      for (let index = 0; index < this.backArr.length; index++) {
        list.push({
          planId: this.backArr[index].planId,
          studentNumber: this.backArr[index].studentNumber,
          examinationId: this.examinationId,
        });
      }
      let params = {
        list,
        examinationId: this.examinationId,
      };
      this.$post("/biz/examination/assignExam", params).then((res) => {
        if (res.status == 0) {
          this.dialogVisible = false;
          this.getData(-1);
          this.$message({
            type: "success",
            message: res.message,
          });
        }
      });
    },
    //选择计划 -- 取消
    setCancel() {
      this.dialogVisible = false;
      this.dialogTitle = "";
      this.compOccupationName = "";
    },
    //生产准考证
    handleProductionPermit(row) {
      this.dialogProductionPermit = true;
      this.examinationId = row.examinationId; //考务id
      this.examNumber = row.examNumber; //考务id
    },
    //生产准考证 -- 确定
    setProductionPermit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let parameter = {
            roomNumber: this.ruleForm.roomNumber, //考场数量
            customizeExamNumber: this.ruleForm.customizeExamNumber, //考场人数
            examinationId: this.examinationId, //考务id
            examNumber: this.examNumber, //考试人数
          };
          if (this.ruleForm.examNo) {
            parameter.examNo = this.ruleForm.examNo;
          }
          this.$post("/biz/examination/production/admitCard", parameter)
            .then((ret) => {
              if (ret.status == 0) {
                this.$message({
                  message: "生成成功",
                  type: "success",
                });
                this.setProductionPermitCancel();
                this.getData(-1)
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
    //生产准考证 -- 取消
    setProductionPermitCancel() {
      this.dialogProductionPermit = false;
      this.ruleForm = {};
      this.$refs["ruleForm"].clearValidate();
    },
    //查看学员
    handleLookStudent() {
      this.$message({
        message: "此功能暂未开发!",
        type: "warning",
      });
    },
    /* 发布 */
    handleIssue(row) {
      this.$post('/biz/examination/changePublish', {examinationId:row.examinationId,isPublish:true}).then(ret => {
        if(ret.status == 0) {
          this.$message.success('发布成功')
          this.getData(-1)
        }
      })
    },
    // 下载准考证
    downloadAdmissionTicket(row) {
      this.$post("/biz/examination/downloadAdmissionTicket", {
        examinationId: row.examinationId,
      }).then((res) => {
        if (res.status == "0") {
          let list = [];
          list.push(res.data);
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
        } else {
          this.$message.error(re.message);
        }
      });
    },
    // 鼠标移入移出
    transToWhite(index, e) {
      e.target.src = require("@/assets/error.png");
    },
    transToBlack(index, e) {
      e.target.src = require("@/assets/close_.png");
    },
    deleteImg(itemobj) {
      var newArr = this.backArr.filter((item) => item.planId != itemobj.planId);
      this.backArr = newArr;
      // this.getPlanList()
      this.multipleTable.find((el, index) => {
        if (el.planId == itemobj.planId) {
          delete this.multipleTable[index];
          this.$refs.multipleTable.toggleRowSelection(el, false);
        }
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scope>
.zsBtn {
  margin-left: 20px;
  button {
    height: 30px;
  }
}
.iconStyle {
  font-size: 2rem;
  color: #6676ff;
  cursor: pointer;
}
.Batch {
  padding: 1rem 0;
  bottom: 0px;
  width: 100%;
  .BatchBox {
    display: flex;
    height: 70px;
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .BatchBoxFirst {
      width: 260px;
      background: #f2f7fd;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .BatchBoxSecond {
      width: 100%;
      .BatchforBox {
        display: flex;
        flex-wrap: wrap;
        .BatchforFlex {
          display: flex;
          align-items: center;
          padding: 5px 20px;
          cursor: pointer;
          .BatchImgBox {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>